import './Landing.css';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { AiOutlineLinkedin, AiOutlineMail } from 'react-icons/ai';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function Landing() {

  return (
    <div className="full landing">
      <div className="big S">
        <ScrollAnimation animateIn='animate__backInLeft' animateOnce={true}>
          S
        </ScrollAnimation>
      </div>
      <div className='big A'>
        <ScrollAnimation animateIn='animate__backInRight' animateOnce={true}>
          A
        </ScrollAnimation>
      </div>

      <Container className='center' >
        <ScrollAnimation className='slogan' animateIn='fadeIn' delay={1000} animateOnce={true} offset={100}>
          <h1>Let me turn your <span className="highlight"> ideas </span> into  <span className="highlight">reality</span>.</h1>
          <h2>freelance software development</h2>
        </ScrollAnimation>

        <div style={{ marginTop: 'auto', color: 'white', fontSize: 50, width: '100%' }}>
          <ScrollAnimation style={{width: '100%'}} animateIn='animate__fadeInDown' animateOnce={true} delay={1500} duration={2} offset={-500}>
            <div className='links' style={{ display: 'blocks', textAlign: 'center', width: '100%' }}>
                  <a className='icon-container' href="https://aani.ca">
                      <BsFileEarmarkPerson style={{}} className='icon' />
                      <p style={{ fontSize: 15 }}>Portfolio</p>
                  </a>

                  <a className='icon-container' href="mailto:somar@aani.ca">
                      <AiOutlineMail className="icon" />
                      <p style={{ fontSize: 15 }}>Email</p>
                  </a>

                  <a className='icon-container' href="https://www.linkedin.com/in/somaraani/">
                      <AiOutlineLinkedin className="icon" />
                      <p style={{ fontSize: 15 }}>LinkedIn</p>
                  </a>
            </div>
          </ScrollAnimation>
        </div>
      </Container>
    </div>

  )
}
