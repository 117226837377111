import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Engineer from './components/engineer/Engineer';
import Landing from './components/landing/Landing';
import './index.css';
import Skills from './components/skills/Skills';
import { IoMdUmbrella } from 'react-icons/io';
import { BrowserView, isMobile } from 'react-device-detect';

export default function Fullpage() {
  return (
    <div className="content">
      <BrowserView>
        <div id="canvas-ogl">
        </div>
      </BrowserView>
      <Landing />
    </div>

  )
}

ReactDOM.render(<Fullpage />, document.getElementById('react-root'));